import React from 'react'
import './Home.css'
import styled from 'styled-components'
import nickTee from './../images/dgb-hero-nick-2.jpg'
import stringTee from './../images/dgb-hero-strings-2.jpg'

function Home() {
  return (
    <Container>
      <a href="https://shop.dontgetbrainwashed.com">
        <RowContainer>
          <Left
            dangerouslySetInnerHTML={{
              __html: `<video class="videoStyle" loop muted autoplay playsinline>
            <source src="https://media.publit.io/file/manifest.mp4" type="video/mp4" />
            Your browser does not support the video tag.
      </video>`,
            }}
          ></Left>
          <StyledNickTee />
        </RowContainer>
        <Row2Container>
          <StyledStringsTee />
          <Right
            dangerouslySetInnerHTML={{
              __html: `<video class="videoStyle" loop muted autoplay playsinline>
          <source src="https://media.publit.io/file/chicago-day.mp4" type="video/mp4" />
          Your browser does not support the video tag.
    </video>`,
            }}
          ></Right>
        </Row2Container>
      </a>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 75vh;
  padding: 0 10px;
  width: 100%;
  box-sizing: border-box;
`

const RowContainer = styled.div`
  box-sizing: border: box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 75rem;
  @media (max-width: 1200px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 10px;
  }
  @media (max-width: 499px) {
    margin-top: 60px;
  }
`

const Row2Container = styled.div`
  box-sizing: border: box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 75rem;
  @media (max-width: 1200px) {
    flex-direction: column-reverse;
    width: 100%;
    align-items: center;
  }
`

const Left = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  height: 500px;
  width: 50%;
  cursor: default;
  @media (max-width: 1200px) {
    width: 100%;
    height: 350px;
  }
`

const Right = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  height: 500px;
  width: 50%;
  cursor: default;
  @media (max-width: 1200px) {
    width: 100%;
    height: 350px;
  }
`

const StyledNickTee = styled.div`
  width: 50%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 500px;
  background: url(${nickTee});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: 1200px) {
    width: 100%;
    height: 350px;
  }
  @media (max-width: 499px) {
    margin: 0;
  }
`

const StyledStringsTee = styled.div`
  width: 50%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 500px;
  background: url(${stringTee});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: 1200px) {
    width: 100%;
    height: 350px;
  }
  @media (max-width: 499px) {
    margin: 0;
  }
`

export default Home
