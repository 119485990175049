import React from 'react'
import styled from 'styled-components'

function Header() {
  return <Title>DONT GET BRAINWASHED</Title>
}

const Title = styled.h1`
  box-sizing: border-box;
  display: flex;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 7.6vw;
  font-weight: 900;
  justify-content: center;
  align-items: center;
  @media (max-width: 1200px) {
    font-size: 7.4vw;
  }
  @media (max-width: 499px) {
    display: none;
  }
`

export default Header
