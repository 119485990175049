import React, { useContext } from 'react'
import { Context } from './../contexts/Context'
import styled from 'styled-components'

function Footer() {
  const { currentPage } = useContext(Context)
  const [stateCurrentPage, setStateCurrentPage] = currentPage // eslint-disable-line

  const handlePageChange = (page) => {
    setStateCurrentPage(page)
  }
  return (
    <FooterContainer>
      <Nav>
        <Link onClick={() => handlePageChange('home')}>HOME</Link>
        <a
          href="https://shop.dontgetbrainwashed.com"
          style={{ textDecoration: 'none' }}
        >
          <Link>CLOTHING</Link>
        </a>
        <Link onClick={() => handlePageChange('vision')}>VISION</Link>
        <a
          href="https://instagram.com/dontgetbrainwashed"
          style={{ textDecoration: 'none' }}
        >
          <Link>INSTAGRAM</Link>
        </a>
        {/* <Link onClick={() => handlePageChange('terms')}>TERMS</Link> */}
      </Nav>
      <FooterDiv>
        DONT GET BRAINWASHED REGISTERED 2022
        <br />
        ALL RIGHTS RESERVED
      </FooterDiv>
    </FooterContainer>
  )
}

const Link = styled.span`
  color: #000;
  font-family: Helvetia, Arial, sans-serif;
  text-decoration: none;
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
`

const FooterContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (max-width: 499px) {
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 30px;
  }
`

const Nav = styled.div`
  width: 55%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2rem;
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 499px) {
    display: none;
  }
`

const FooterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Helvetia, Arial, sans-serif;
  font-size: 0.7rem;
  font-weight: 900;
  margin-bottom: 2rem;
  @media (max-width: 499px) {
    width: 100%;
  }
`

export default Footer
