import React, { useState, useContext } from 'react'
import { Context } from '../contexts/Context'
import styled from 'styled-components'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import MenuItem from '@mui/material/MenuItem'

// const pages = ['Home', 'Clothing', 'Vision', 'Instagram', 'Terms']

const MobileNavigation = () => {
  const { currentPage } = useContext(Context)
  const [stateCurrentPage, setStateCurrentPage] = currentPage // eslint-disable-line
  const [anchorElNav, setAnchorElNav] = useState(null)

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = (page) => {
    setAnchorElNav(null)
    let currPage = page.split(' ')[0]
    setStateCurrentPage(currPage.toLowerCase())
  }

  return (
    <NavContainer>
      <AppBar
        position="static"
        sx={{ boxShadow: 0 }}
        style={{
          backgroundColor: '#e2ffe2',
        }}
      >
        <Container maxWidth="xxl">
          <Toolbar disableGutters>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'flex', md: 'none' },
              }}
            >
              <IconButton
                size="xl"
                aria-label="hamburger menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                style={{ color: '#000' }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: {
                    xs: 'block',
                    md: 'none',
                  },
                }}
              >
                {/* {pages.map((page) => (
                  <MenuItem
                    key={page}
                    onClick={() => handleCloseNavMenu(page)}
                    style={{ backgroundColor: '#e2ffe2' }}
                  >
                    <Typography
                      textAlign="center"
                      style={{ fontWeight: '600' }}
                    >
                      {page.toUpperCase()}
                    </Typography>
                  </MenuItem>
                ))} */}
                <MenuItem
                  onClick={() => handleCloseNavMenu('home')}
                  style={{ backgroundColor: '#e2ffe2' }}
                >
                  <Typography textAlign="center" style={{ fontWeight: '600' }}>
                    HOME
                  </Typography>
                </MenuItem>
                <MenuItem
                  style={{
                    backgroundColor: '#e2ffe2',
                  }}
                >
                  <Typography textAlign="center" style={{ fontWeight: '600' }}>
                    <a
                      href="https://shop.dontgetbrainwashed.com"
                      style={{ textDecoration: 'none', color: '#000' }}
                    >
                      CLOTHING
                    </a>
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => handleCloseNavMenu('vision')}
                  style={{ backgroundColor: '#e2ffe2' }}
                >
                  <Typography textAlign="center" style={{ fontWeight: '600' }}>
                    VISION
                  </Typography>
                </MenuItem>
                <MenuItem
                  style={{
                    backgroundColor: '#e2ffe2',
                  }}
                >
                  <Typography textAlign="center" style={{ fontWeight: '600' }}>
                    <a
                      href="https://instagram.com/dontgetbrainwashed"
                      style={{ textDecoration: 'none', color: '#000' }}
                    >
                      INSTAGRAM
                    </a>
                  </Typography>
                </MenuItem>
                {/* <MenuItem
                  onClick={() => handleCloseNavMenu('terms')}
                  style={{ backgroundColor: '#e2ffe2' }}
                >
                  <Typography textAlign="center" style={{ fontWeight: '600' }}>
                    TERMS
                  </Typography>
                </MenuItem> */}
              </Menu>
            </Box>
            {/* <FitnessCenterIcon
              sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}
            /> */}
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 1,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: "'Helvetica', Arial, sans-serif",
                fontWeight: 600,
                fontSize: '1.35rem',
                color: '#000',
                textDecoration: 'none',
              }}
            >
              DONT GET BRAINWASHED
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
    </NavContainer>
  )
}

const NavContainer = styled.div`
  display: flex;
  width: 100%;
  position: fixed;
  z-index: 5;
  top: 0;
  @media (min-width: 500px) {
    display: none;
  }
`
export default MobileNavigation
