import React from 'react'
import './Home.css'
import styled from 'styled-components'
import punchingMan from './../images/dgb-punching-man.png'

function Vision() {
  return (
    <Container>
      <Paragraph>
        DONT GET BRAINWASHED is a vision i’ve had that has been evolving ever
        since october 22, 2011 when my older brother nick commit suicide
      </Paragraph>
      <Paragraph>
        after that tragedy i learned how to heal my wounds and i feel it is my
        purpose to share the knowledge and wisdom that has saved me
      </Paragraph>
      <Paragraph>
        a way to help kids like me heal their trauma and love/enjoy/make the
        most of this temporary life. nobody should feel the need to take their
        own life
      </Paragraph>
      <Paragraph>
        i believe my brother would still be here today if he felt open enough to
        talk about his true feelings, emotions, and ideas
      </Paragraph>
      <Paragraph>
        everything created through DONT GET BRAINWASHED comes from true
        feelings, ideas, and personal experience
      </Paragraph>
      <Paragraph>
        inspiring individuals to love themselves, believe in their own
        purpose/vision, and tap into their true potential
      </Paragraph>
      <Paragraph>
        i am committed to opening up the conversation on mental health and
        breaking the stigma, inspiring kids to share their truth with confidence
        and not feel the need to end their own lives
      </Paragraph>
      <Paragraph>
        creating a community that collaborates on projects, empowers each other,
        and inspires authentic expression
      </Paragraph>
      <Paragraph>
        DONT GET BRAINWASHED will be an outlet to express authentic creative
        ideas and feelings with confidence and inspire everyone to do the same
      </Paragraph>
      <Paragraph>
        to create art that invokes emotions and real change in people
      </Paragraph>
      <Paragraph>
        to wake individuals up to their true potential/power within and realize
        their own purpose/vision
      </Paragraph>
      <Paragraph>
        to start a loving, peaceful, and joyous revolution with art to create a
        more loving, peaceful, and joyous existence for us all
      </Paragraph>
      <Paragraph>thank you for supporting the vision</Paragraph>
      <Paragraph>
        it's possible if we work together, humanity in harmony
      </Paragraph>
      <CenteredParagraph>
        DONT GET BRAINWASHED
        <br />
        "FOR HUMANITY" 22
      </CenteredParagraph>
      <ImageContainer>
        <img src={punchingMan} alt="punching man" width="100%" />
      </ImageContainer>
    </Container>
  )
}

const ImageContainer = styled.div`
  width: 30%;
  @media (max-width: 499px) {
    width: 80%;
  }
`

const Paragraph = styled.p`
  width: 30%;
  text-align: justify;
  font-family: Helvetica;
  font-size: 1.022rem;
  font-weight: 600;
  margin-bottom: 1rem;
  @media (max-width: 499px) {
    width: 80%;
  }
`

const CenteredParagraph = styled.p`
  width: 30%;
  text-align: center;
  font-family: Helvetica;
  font-size: 1.022rem;
  font-weight: 600;
  margin-bottom: 1rem;
  @media (max-width: 499px) {
    width: 80%;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
  @media (max-width: 499px) {
    margin-top: 75px;
  }
`

export default Vision
