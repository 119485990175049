import React, { createContext, useState } from 'react'

export const Context = createContext()

export const ContextProvider = (props) => {
  const [currentPage, setCurrentPage] = useState('clothing')

  return (
    <Context.Provider value={{ currentPage: [currentPage, setCurrentPage] }}>
      {props.children}
    </Context.Provider>
  )
}
