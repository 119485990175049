import React from 'react'
import { ContextProvider } from './contexts/Context'
import App from './App'
function AppContextWrapper() {
  return (
    <ContextProvider>
      <App />
    </ContextProvider>
  )
}

export default AppContextWrapper
