import React, { useContext } from 'react'
import { Context } from './contexts/Context'
import MobileNavigation from './components/MobileNavigation'
import Header from './components/Header'
import Home from './components/Home'
import Vision from './components/Vision'
import Footer from './components/Footer'

function App() {
  const { currentPage } = useContext(Context)
  const [stateCurrentPage] = currentPage
  return (
    <>
      <MobileNavigation />
      <Header />
      {stateCurrentPage === 'home' && <Home />}
      {stateCurrentPage === 'clothing' && <Home />}
      {stateCurrentPage === 'vision' && <Vision />}
      <Footer />
    </>
  )
}

export default App
